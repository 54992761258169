import React, { useEffect } from 'react'
import Layout from 'src/components/layout/layout'
import SEO from 'src/components/seo'
import { usePrivateRoute, useScopeAccess } from 'src/utils/auth'
import { navigate } from 'gatsby'
import { getClientConfig } from 'src/config/config'

const IndexPage: React.FC = () => {
  usePrivateRoute()
  const { getAccessInfo } = useScopeAccess()
  const redirectsToTry = getClientConfig().rootUrlRedirectsToTry

  useEffect(() => {
    if (redirectsToTry?.length && typeof window !== 'undefined') {
      const redirect = redirectsToTry.find(r => getAccessInfo(r).hasAccess)
      if (redirect) navigate(redirect)
    }
  }, [getAccessInfo])

  return (
    <Layout>
      <SEO title="Home" />
    </Layout>
  )
}

export default IndexPage
